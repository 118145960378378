<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-end h-100 w-100 pr-5">
        <b-button
          variant="success"
          class="b-button-custom"
          @click="cadastrarNovidade"
        >
          Cadastrar Novidade</b-button
        >
      </div>
    </div>
    <div class="card-body px-4">
      <Toast position="top-right" />

      <div
        class="d-flex justify-content-center align-items-center h-75"
        v-if="carregando"
      >
        <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
          ><v-progress-circular
            indeterminate
            size="24"
            color="#ef720f"
            width="2"
            rotate="180"
          ></v-progress-circular
        ></v-progress-circular>
      </div>
      <div
        v-else
        class="d-flex flex-column justify-content-between align-items-between h-100"
      >
        <div class="d-flex flex-column justify-content-between h-100 w-100">
          <b-table
            id="tabelaNovidades"
            small
            :current-page="currentPage"
            :fields="fields"
            :items="itens"
            :per-page="selectQuant.value"
            responsive="sm"
            hover
            sort-by="novidade_id"
            :sort-desc="false"
            ref="refteste"
            primary-key="novidade_id"
            @row-clicked="editarNovidade"
          >
            <template #cell(novidade_id)="data">
              <label class="mt-3">
                {{ data.value }}
              </label>
            </template>

            <template #cell(nov_titulo)="data">
              <label class="mt-3">
                {{ data.value || "-" }}
              </label>
            </template>

            <template #cell(nov_descricao)="data">
              <label class="mt-3">
                {{ data.value || "-" }}
              </label>
            </template>

            <template #cell()="row">
              <i>{{ row.value }}</i>
              <b-dropdown
                no-caret
                variant="outline-secondary"
                id="dropdown-dropleft"
                dropleft
                class="border-0"
              >
                <template #button-content>
                  <b-icon-three-dots-vertical />
                </template>
                <!-- <b-dropdown-item href="#">Detalhes</b-dropdown-item> -->
                <b-dropdown-item href="#" @click="editarNovidade(row.item)"
                  >Editar</b-dropdown-item
                >

                <b-dropdown-item
                  @click="mostrarDeletarNovidadeSwal(row.item)"
                  href="#"
                  >Remover</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <div class="d-flex w-100">
            <b-row class="w-100">
              <b-col md="4"
                ><b-form-select
                  v-model="selectQuant.value"
                  :options="selectQuant.options"
                ></b-form-select
              ></b-col>
              <b-col md="8"
                ><b-pagination
                  v-model="currentPage"
                  :total-rows="itens.length"
                  :per-page="selectQuant.value"
                  aria-controls="tabelaNovidades"
                  align="fill"
                  @change="salvarConfiguracoes"
                ></b-pagination
              ></b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../painel/automacao/api.links.js";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import store from "@/core/services/store/store";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Toast
  },
  data() {
    return {
      novidade_selecionada: {},
      carregando: true,
      fields: [
        {
          key: "novidade_id",
          label: "ID",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "80px !important" }
        },
        {
          key: "nov_titulo",
          label: "Título",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "250px !important" }
        },
        {
          key: "nov_descricao",
          label: "Descrição",
          class: "cursor-pointer align-middle",
          thStyle: { width: "250px !important" }
        },
        {
          key: "opcoes",
          label: "Opções",
          class: "text-center",
          thStyle: { width: "60px !important" }
        }
      ],
      itens: [],
      data: {},
      currentPage: 1,
      perPage: 10,
      selectQuant: {
        value: "30",
        options: [
          {
            text: "Exibir 10 registros por página",
            value: "10"
          },
          {
            text: "Exibir 15 registros por página",
            value: "15"
          },
          {
            text: "Exibir 30 registros por página",
            value: "30"
          }
        ]
      }
    };
  },

  methods: {
    cadastrarNovidade() {
      this.$router.push({
        name: "novidade.cadastro"
      });
    },
    editarNovidade(novidade) {
      this.$router.push({
        name: "novidade.editar",
        params: {
          novidade
        }
      });
    },
    mostrarDeletarNovidadeSwal(novidade) {
      this.novidade_selecionada = novidade;

      Swal.fire({
        title:
          'Você deseja remover a novidade "' +
          this.novidade_selecionada.nov_titulo +
          '"?',
        text: "Não será possivel reverter essa ação! Isso apagará todas as notificações associadas",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Novidade Removida",
            `Novidade "${this.novidade_selecionada.nov_titulo}" removida com sucesso.`,
            "success"
          );
          ApiService.delete(
            API_LINKS.novidades + "/" + this.novidade_selecionada.novidade_id
          ).then((_) => {
            this.pegarNovidades();
          });
        }
      });
    },
    salvarConfiguracoes(currentPage) {
      store.state.paginacao = {
        perPage: this.selectQuant.value,
        currentPage: currentPage,
        licenciadaSelecionadaFiltrada: this.licenciadaSelecionadaFiltrada
      };
    },

    async pegarNovidades() {
      this.carregando = true;
      try {
        const res = await ApiService.get(API_LINKS.novidades, "", false);
        this.itens = res.data.novidades;
        this.carregando = false;
      } catch (error) {
        this.carregando = false;
        console.error(error);
      }
    }
  },
  mounted() {
    this.pegarNovidades();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Novidades"
      }
    ]);
  },
  watch: {
    "selectQuant.value"(newValue) {
      this.salvarConfiguracoes(this.currentPage);
    }
  }
};
</script>

<style scoped>
.modal-deletar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.camp-center {
  display: flex;
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.p-multiselect-trigger-icon.pi.pi-chevron-down::before {
  content: none;
}

table#tabelaNovidades .flip-list-move {
  transition: transform 0.5s;
}
</style>
